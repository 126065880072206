<template>
  <Layout :pagetitle="'Relatórios de faturamento'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-3">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-sm-12 col-lg-4 mb-2">
                <select-seller
                  v-model="filter.seller_id"
                  placeholder="Pesquisar por vendedor"
                />
              </div>
              <div class="col-sm-12 col-lg-4 mb-2">
                <select-contest
                  v-model="filter.contest_id"
                  placeholder="Pesquisar por sorteio"
                />
              </div>
              <div
                class="col-sm-12 col-lg-2 pt-1"
                style="text-align: right;"
              >
                <h3 class="text-info">
                  Pedidos: {{ ordersCount }}
                </h3>
              </div>
              <div
                class="col-sm-12 col-lg-2 pt-1"
                style="text-align: right;"
              >
                <a
                  v-if="items.length > 0"
                  :href="exportURL"
                  class="btn btn-outline-danger waves-effect waves-light"
                >
                  Exportar registros ({{ items.length }})
                </a>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-lg-2">
                <select
                  v-model="filter.group_by"
                  class="form-select"
                >
                  <option value="day">
                    Agrupar por ({{ groupByOptions[filter.group_by] }})
                  </option>
                  <option
                    v-for="(label, key) in groupByOptions"
                    :key="key"
                    :value="key"
                  >
                    {{ label }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-lg-3">
                <input
                  v-model="filter.confirmed_at.from"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de confirmação(inicial)"
                >
              </div>
              <div class="col-sm-12 col-lg-3">
                <input
                  v-model="filter.confirmed_at.to"
                  v-maska="'##/##/####'"
                  type="text"
                  class="form-control"
                  placeholder="Data de confirmação(final)"
                >
              </div>
              <div
                class="col-sm-12 col-lg-2 pt-1"
                style="text-align: right;"
              >
                <h3 class="text-success">
                  Total: {{ $n(ordersAmount, 'currency') }}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="card mt-3">
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-striped mb-4"
              >
                <thead>
                  <tr>
                    <!-- <th scope="col">Sorteio</th> -->
                    <th scope="col">
                      {{ groupByOptions[filter.group_by] }}
                    </th>
                    <th scope="col">
                      Total
                    </th>
                    <th scope="col">
                      Pedidos
                    </th>
                    <!--
                <th scope="col">Aberto em</th>
                <th scope="col">Pago em</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in items"
                    :key="key"
                  >
                    <td v-if="filter.group_by === 'day'">
                      {{ $d(item.date, 'short') }}
                    </td>
                    <td v-else>
                      {{ item.date }}
                    </td>
                    <td>{{ $n(parseFloat(item.amount), 'currency') }}</td>
                    <td>{{ item.count }}</td>
                    <!--  <td>{{ order.total? $n(order.total, 'currency') : '' }}</td>
                <td>
                   <span class="badge rounded-pill" :class="statusGet(order.status, 'class')">
                    {{ statusGet(order.status, 'label') }}
                  </span>
                </td>
                <td>{{ order.confirmed_at? $d(order.confirmed_at, 'info') : '' }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import { computed, onMounted, ref, watch } from 'vue'
import SelectContest from '@/components/select-contest'
import SelectSeller from '@/components/select-seller'
import debounce from '@/utils/debounce'
import { reports } from './api'
import { axios } from '@/axios'

const statusMap = {
  CONFIRMED: {
    label: 'Confirmado',
    class: 'badge-soft-success'
  },
  CANCELED: {
    label: 'Cancelado',
    class: 'badge-soft-danger'
  },
  PROCESSING: {
    label: 'Processando',
    class: 'badge-soft-warning'
  },
  PENDING: {
    label: 'Pendente',
    class: 'badge-soft-primary'
  }
}

const groupByOptions = {
  day: 'Dia',
  week: 'Semana',
  month: 'Mês',
  year: 'Ano'
}

export default {
  setup () {
    const items = ref([])
    //
    const ordersList = ref([])
    const page = ref(1)
    const pages = ref(0)
    const total = ref(0)
    const filter = ref({
      group_by: 'day',
      order_by: 'day',
      seller_id: null,
      contest_id: null,
      user_id: null,
      status: null,
      created_at: {
        from: null,
        to: null
      },
      confirmed_at: {
        from: null,
        to: null
      }
    })

    const fetchParams = computed(() => {
      const params = {
        group_by: filter.value.group_by,
        order_by: filter.value.order_by
      }

      if (between.value) {
        params.between = between.value
      }

      if (filter.value.seller_id) {
        params.seller_id = [filter.value.seller_id.value]
      }

      if (filter.value.contest_id) {
        params.contest_id = [filter.value.contest_id.value]
      }

      return params
    })

    const fetchReport = debounce(() => {
      const params = {
        group_by: filter.value.group_by,
        order_by: filter.value.order_by
      }

      if (between.value) {
        params.between = between.value
      }

      if (filter.value.seller_id) {
        params.seller_id = [filter.value.seller_id.value]
      }

      if (filter.value.contest_id) {
        params.contest_id = [filter.value.contest_id.value]
      }

      reports({
        billing: fetchParams.value
      }).then((data) => {
        items.value = data.billing.current
      })
    }, 1000)

    const dateToISO = (date) => {
      if (date && date.length === 10) {
        return date.split('/').reverse().join('-')
      }
      return null
    }

    const between = computed(() => {
      if (
        filter.value.confirmed_at.from &&
          filter.value.confirmed_at.to
      ) {
        const between = [
          dateToISO(filter.value.confirmed_at.from),
          dateToISO(filter.value.confirmed_at.to)
        ].join(',')

        return between.length === 21 ? between : null
      }
      return null
    })

    const ordersAmount = computed(() => {
      return items.value.reduce((a, c) => {
        a += parseFloat(c.amount)
        return a
      }, 0)
    })

    const ordersCount = computed(() => {
      return items.value.reduce((a, c) => {
        a += c.count
        return a
      }, 0)
    })

    const exportURL = computed(() => {
      const { baseURL } = axios.defaults
      return `${baseURL}/reports/billing`
    })

    const statusGet = (status, key) => statusMap[status][key]

    watch(filter, () => {
      items.value = []
      fetchReport()
    }, { deep: true })

    onMounted(() => {
      fetchReport()
    })

    return {
      page,
      pages,
      total,
      ordersList,
      // fetchOrders,
      statusGet,
      filter,
      statusMap,
      //
      items,
      groupByOptions,
      ordersAmount,
      ordersCount,
      exportURL
    }
  },
  components: {
    // SelectCustomer,
    SelectContest,
    SelectSeller,
    Layout
  }
}
</script>
